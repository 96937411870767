<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <a @click="$router.go(-1)">{{ $route.query.type }}列表</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item style="color: #15b7dd">#{{
        $route.query.name
        }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="contentList">
      <div class="contentItem" v-for="(item, index) in list" :key="index" @click="routerPush(item)">
        <img :src="item.listPhoto" alt="" />
        <div class="content">
          <div class="left">
            <p class="name">{{ item.productName }}</p>
            <div class="navs">
              <span class="type" v-if="item.productType == 1">{{ item.categoryName }} 丨视频数量：{{ item.classHour }}个</span>
            </div>
          </div>
          <div class="right">
            <div class="price">
              <p class="new">
                <span v-if="item.couponPrice"><span class="new_point">￥</span>{{ managePrice(item.couponPrice)[0] }}<span
                    class="new_point">.{{ managePrice(item.couponPrice)[1] }}</span></span>
                <span class="free" v-else>免费</span>
              </p>
              <p class="old" v-if="item.original && item.couponPrice">
                ￥{{ item.originalPrice.toFixed(2) }}
              </p>
            </div>
            <div class="button2">
              查看详情
              <img src="@/assets/image/Group 5446.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <a-spin class="spin" v-show="loading" />
      <!-- 暂无课程 -->
      <a-empty v-if="list.length == 0 && !loading" description="" style="padding-top: 50px"
        :image="require('@/assets/image/stateHint/icon_nocourse.png')" :image-style="{
          height: '180px',
        }">
        <span class="nodata">暂无课程</span>
      </a-empty>
      <a-pagination v-show="total" :hideOnSinglePage="true" show-quick-jumper :default-current="1" :total="total"
        @change="onChange" />
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      tagId: null, //标签id
      columnCode: undefined, // 公益code
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      loading: false,
    };
  },
  // 事件处理器
  methods: {
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split('.')
      return txt
    },

    onChange(e) {
      this.pageNum = e
      this.getList()
    },

    routerPush(e) {
      // console.log("=-=-=-=-", JSON.stringify(e));
      if (e.productType == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
          this.$AES.encode_data(e.productId + "") +
          "&code=" +
          e.productCode + "&name=" + this.$route.query.name
        );
      } else if (e.productType == 2) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
          this.$AES.encode_data(e.productId + "")
        );
      }

    },

    getList() {
      this.loading = true;
      const url = this.$route.query.type == '公益' ? '/hxclass-pc/ProductColumnRelation/client/list' : '/hxclass-pc/pc-mall/client/tags'
      this.$ajax({
        url: url,
        method: "get",
        params: {
          tagId: this.tagId, // 分类编码
          columnCode: this.columnCode,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.list = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.tagId) {
      this.tagId = Number(this.$AES.decode_data(this.$route.query.tagId));
    } else if (this.$route.query.columnCode) {
      this.columnCode = this.$AES.decode_data(this.$route.query.columnCode);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;

  ::v-deep.ant-pagination {
    text-align: right;
    padding-right: 29px;
    padding-top: 45px;
  }

  .contentList {
    margin-top: 24px;
    padding: 20px 30px;
    min-height: 300px;
    border-radius: 5px;
    background: #ffffff;
    position: relative;

    .contentItem {
      background: #ffffff;
      padding: 20px;
      border-bottom: 1px solid #e1e1e1;
      transition: all 0.5s;
      display: flex;
      cursor: pointer;

      >img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        transition: all 0.5s;
        background-repeat: no-repeat;
        object-fit: cover;
      }

      .content {
        padding: 8px 24px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .left {
          width: 580px;

          .name {
            cursor: pointer;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
          }

          .navs {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            height: 24px;
            overflow: hidden;
            flex-wrap: wrap;
            margin-top: 16px;
            word-wrap: break-word;
            word-break: normal;
            line-height: 21px;

            span {
              display: inline-block;
            }

            .type {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #1e97b4;
              line-height: 21px;
              margin-right: 16px;
            }

            .nav {
              font-size: 12px;
              font-family: PingFangMedium;
              color: #EC6C01;
              line-height: 18px;
              border-radius: 4px;
              border: 1px solid #EC6C01;
              padding: 2px 8px;
              font-weight: 400;
              margin-right: 8px;
            }
          }
        }

        .right {
          width: 200px;

          .price {
            display: flex;
            align-items: center;
            margin-top: 19px;

            .new {
              font-size: 16px;
              font-family: PingFangMedium;
              color: #EC6C01;
              line-height: 21px;

              span {
                font-size: 28px;
              }

              .new_point {
                font-size: 16px;
              }

              .free {
                font-size: 24px;
              }
            }

            .old {
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: 400;
              text-decoration: line-through;
              color: #999999;
              line-height: 21px;
              margin-left: 9px;
            }
          }

          .button2 {
            text-align: left;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #15b7dd;
            cursor: pointer;
            line-height: 34px;
            margin-top: 32px;

            img {
              width: 16px !important;
              height: 16px !important;
              margin-left: 8px;
            }
          }

          .button {
            width: 134px;
            height: 40px;
            background: #15b7dd;
            border-radius: 2px;
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            &:hover::before {
              left: 100%;
            }

            p {
              margin-left: 5px;
            }
          }

          .button::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
            transition: all .5s;
          }
        }
      }
    }

    .contentItem:first-child {
      .white {
        top: 2px !important;
      }
    }

    .contentItem:hover {
      position: relative;
      z-index: 2;
      border-radius: 5px;
      border-bottom: 1px solid #ffffff;
      box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);

      >img {
        transform: scale(1.05, 1.05)
      }
    }

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
